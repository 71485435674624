<template>
<div id="page-entrada-sucursal">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Entrada Física de Mercancía" class="elevation-1 px-5 py-3">


          <v-card-text>
            <v-row>
              <v-col lg="4" md="4" sm="4">
                <v-text-field label="Ingrese Código de Orden de Entrada" v-model="codigo" :rules="[rules.required]"
                  @keyup.enter="codigo = codigo.toString().toUpperCase(); buscarCodigo()"
                  @input="upperCaseInput('codigo')" ref="ingresacodigo" :readonly="model._id!=''">
                </v-text-field>
              </v-col>
              <v-col lg="2" md="2" sm="2" v-if="model._id==''">
                <v-btn small color="info" @click="buscarCodigo()">
                  <v-icon class="white--text">search</v-icon> Buscar
                </v-btn>
              </v-col>

              <v-col lg="4" md="4" sm="4" v-if="model._id!=''">
                <v-text-field label="Ingrese Código de Artículo" v-model="codigo_articulo" :rules="[rules.required]"
                  @keyup.enter="codigo_articulo = codigo_articulo.toString().toUpperCase(); buscarArticulo()"
                  @input="upperCaseInput('codigo_articulo')" ref="ingresaarticulo">
                </v-text-field>
              </v-col>
              <v-col lg="2" md="2" sm="2" v-if="model._id!=''">
                <v-btn small color="info" @click="buscarArticulo()">
                  <v-icon class="white--text">search</v-icon> Buscar
                </v-btn>
              </v-col>
            </v-row>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="beforeTable">
                  <div style="text-align:right" v-show="registros.items.length>0">
                    <v-btn small text outlined color="green darken-2"
                        @click.native="aceptar_todo()" title="Aceptar Todo" v-tippy>
                        <v-icon>done</v-icon> Aceptar Todo
                    </v-btn>
                  </div><br>
                </template>
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="codigo_articulo" slot-scope="props">
                  {{ props.row.codigo_articulo.join(", ") }}
                </template>
                <template slot="actions" slot-scope="props">
                  <span v-if="props.row.estatus!='Recibido'">
                    <v-btn small fab dark color="green  darken-2" @click.native="mostrarEditar(props.row)" title="Aceptar" v-tippy>
                      <v-icon>done</v-icon>
                    </v-btn>&nbsp;
                  <v-btn small fab dark color="blue darken-2"
                      @click.native="mostrarAgregar(props.row)" title="Agregar" v-tippy>
                      <v-icon>add</v-icon>
                  </v-btn>
                  </span>
                  <span v-else>
                    Ya fue recibido
                  </span>
                </template>
              </v-client-table>
              <br>
              <v-row justify="center" v-if="model._id!=''">
                <!-- (15-OCT-2024): Martin solicitó quitar este botón, para que la entrada se la den ellos y no personal de almacen -->
                <!-- (16-OCT-2024): Alvaro solicitó volver a activarlo -->
                <v-btn color="success" dark @click="finalizarEntrada()">
                  <v-icon>done_all</v-icon> Finalizar Entrada
                </v-btn>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <v-btn color="info" @click="buscarOtro()" v-if="model._id!=''">
                  <v-icon class="white--text">search</v-icon> Buscar Otra Orden
                </v-btn>
              </v-row>

            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modalEditar" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cantidad Real de Entrada</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalEditar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row justify="center">
              <v-col sm="3" md="3" lg="3" style="text-align:right !important;">
                <v-btn small color="grey" @click.native="(cantidad_editable!=null && cantidad_editable>0)?cantidad_editable=parseFloat(cantidad_editable)-1:cantidad_editable=0">
                  <v-icon>remove</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:center !important;">
                <v-text-field label="Cantidad" v-model="cantidad_editable" ref="ingresacantidad" 
                v-on:keydown.enter.prevent='1' @keyup.enter="editar()" :rules="[validaCantidadFloat]"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:left !important;">
                <v-btn small color="grey" @click.native="(cantidad_editable!=null && cantidad_editable>=0)?cantidad_editable=parseFloat(cantidad_editable)+1:cantidad_editable=0">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn @click.native="modalEditar = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="success" @click.native="editar()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <!-- MODAL AGREGAR -->
  <v-dialog v-model="modalAgregar" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cantidad a Agregar</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAgregar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row justify="center">
              <v-col sm="3" md="3" lg="3" style="text-align:right !important;">
                <v-btn small color="grey" @click.native="(cantidad_agregar!=null && cantidad_agregar>0)?cantidad_agregar=parseFloat(cantidad_agregar)-1:cantidad_agregar=0">
                  <v-icon>remove</v-icon>
                </v-btn>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:center !important;">
                <v-text-field label="Cantidad" v-model="cantidad_agregar" ref="ingresacantidadagregar"
                  :rules="[validaCantidadFloat]" v-on:keydown.enter.prevent='1' @keyup.enter="agregar()"></v-text-field>
              </v-col>
              <v-col sm="3" md="3" lg="3" style="text-align:left !important;">
                <v-btn small color="grey" @click.native="(cantidad_agregar!=null && cantidad_agregar>=0)?cantidad_agregar=parseFloat(cantidad_agregar)+1:cantidad_agregar=0">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn @click.native="modalAgregar = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn color="success" @click.native="agregar()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <!-- MODAL BUSCAR -->
  <v-dialog v-model="modalBuscar" max-width="50%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Buscar artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-autocomplete v-model="buscar_codigo_articulo" :items="articulos" :hide-no-data="true" :loading="isLoading" ref="selectarticulo" :search-input.sync="search" placeholder="Ingrese nombre de artículo para buscar" prepend-icon="search"
                  v-on:keydown.enter.prevent='1' :hide-selected="true" item-text="nombre" item-value="codigo_barras" @change="seleccionarArticulo()" label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cerrarModal()">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL OBSERVACION -->
  <v-dialog v-model="modalObservacion" max-width="50%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Agregar Observaciones</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formobservacion" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones" rows="4" v-model="observacion" :rules="[rules.required]"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalObservacion=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="finalizarObservacion()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL SUPERVISOR -->
  <v-dialog v-model="modal_supervisor" max-width="30%" persistent>
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">person</v-icon>&nbsp;
          <span class="subheading">
            <strong>Se necesita aprobación de un Supervisor</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formsupervisor" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field append-icon="person" name="login" label="Número Supervisor" type="text" hide-details v-model="no_identificacion" @keyup.enter="focusPass()" :rules="[rules.required]" color="primary" autocomplete="new-username" autofocus @focus="$event.target.select()" ref="login" class="input-group--focused"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field append-icon="lock" @keyup.enter="(codigo_supervisor !== '')? verificaSupervisor() : ''" label="Código Supervisor" type="password" v-model="codigo_supervisor" :rules="[rules.required]" color="success" ref="password" autocomplete="new-password" class="input-group--focused secure"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal_supervisor=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="verificaSupervisor()">
          <v-icon>done</v-icon> Aceptar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
export default {
  components: {},
  mounted: function() {

    if (!this.verificaPermiso('s.inventarios.entradas')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },

  created: function() {
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
        this.$router.go();            
    }
    this.$nextTick(() => this.$refs.ingresacodigo.focus());
  },
  watch: {

    search(val) {

      //Si no ha escrito 4 letras o mas
      if (val == null || val.length < 4) return

      // Si todavía esta buscando
      if (this.isLoading) return

      this.isLoading = true

      let data = {
        "selector": {
          "type": "articulos",
          "nombre": {
            "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        },
        "use_index":"_design/7d6652dbf4254f0af6f48c9a54fe2f8afe9f3f0f"
        /*"fields": [
            "nombre", "estatus"
        ]*/
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos = response.data.docs;
          } else
            this.articulos = [];

        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });

        }).then(() => {
          this.isLoading = false;
        });
    },
  },
  data() {
    return {
      no_identificacion:"",
      codigo_supervisor:"",
      modal_supervisor:false,

      sucursal_activa:"",
      edicion_directa: false,
      articulosModificados: [],
      isLoading: false,
      modalBuscar: false,
      modalObservacion: false,
      observacion: "",
      codigo: "",
      tipo_orden: "", //Traspaso, Compra, ...(las que se agreguen)
      codigo_articulo: "",
      articuloEditable: {},
      cantidad_editable: "",
      cantidad_agregar: "",
      model_filters: "",
      props_paginacion: {
        infiniteHandler: this.infiniteHandler,
        total_registros: 0
      },
      fab: [],
      name: "datagrid",
      columns: [
        "codigo_articulo",
        "nombre_articulo",
        "cantidad_salida",
        "cantidad_recibida",
        "actions"
      ],
      show_loading: true,
      options: {
        filterable: false,
        headings: {
          "codigo_articulo": "Código",
          "nombre_articulo": "Artículo",
          "cantidad_salida": "Cantidad",
          "cantidad_recibida": "Cantidad Real Entrada",
          actions: "Editar Cantidad"
        },
        rowClassCallback: function(row) {
          return row.cantidad_salida == row.cantidad_recibida ? 'bg-bien' : row.cantidad_recibida == 0 ? 'bg-default' : 'bg-mal'
        },

      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Inventario",
          href: "",
          disabled: true,
        },
        {
          text: "Entrada Física de Mercancía",
          href: ""
        }
      ],

      modalEditar: false,
      modalAgregar: false,
      update: false,
      articulos: [],
      buscar_codigo_articulo: "",
      model: {
        _id: "",
        _rev: "",
        clave_traspaso: "",
        id_sucursal_origen: "",
        nombre_sucursal_origen: "",
        id_sucursal_destino: "",
        nombre_sucursal_destino: "",
        articulos: [],
        usuario: "",
        fecha: "",
        fecha_finalizado: "",
        estatus: "", //Pendiente, En Proceso de Salida, Salida de Origen, En Proceso de Entrada, Recibido
        //Arreglo de lotes que se afectaron en sucursal destino,
        // esta opción se llena cuando se finaliza el traspaso en la vista de Traspasos
        lotes_destino: [],
      },
      articulo: {
        id_articulo: "",
        nombre_articulo: "",
        codigo_articulo: "",
        cantidad: "",
        lotes: [], //lotes que afecto el articulo en sucursal origen
        estatus: "Pendiente", //Pendiente, Salida (de origen), Recibido (en destino)
        cantidad_recibida: "", //cantidad recibida en destino, ya que puede ser diferente
        lotes_destino: [], //Esta opción se llena cuando se le da entrada articulo por articulo en sucursal destino
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },

    };
  },
  methods: {
    upperCaseInput(fieldName) {
      // Guarda la posición actual del cursor
      const input = event.target;
      const start = input.selectionStart;
      const end = input.selectionEnd;

      // Transforma el valor del campo a mayúsculas
      const fieldValue = this[fieldName];
      const upperCaseValue = fieldValue.toUpperCase();

      // Actualiza el valor del campo en el modelo de datos
      this[fieldName] = upperCaseValue;

      // Actualiza el valor del input en la vista
      input.value = upperCaseValue;

      // Restablece la posición del cursor
      input.setSelectionRange(start, end);
    },
    seleccionarArticulo: function() {
      this.codigo_articulo = this.buscar_codigo_articulo[0]; //Buscamos con el primer código
      this.modalBuscar = false;
      this.buscarArticulo();
    },

    mostrarEditar: function(row) {
      this.articuloEditable = row;
      if (this.tipo_orden == "Traspaso")
        this.cantidad_editable = row.cantidad_salida;
      else if (this.tipo_orden == "Compra")
        this.cantidad_editable = row.cantidad_salida;
      else if (this.tipo_orden == "Venta")
        this.cantidad_editable = row.cantidad_salida;

      this.modalEditar = true;
      setTimeout(() => {
        this.$nextTick(() => this.$refs.ingresacantidad.focus());
      }, 100);

    },

    //Agrega la cantidad a la que ya se lleva
    mostrarAgregar: function(row){
      this.articuloEditable = row;
      this.cantidad_agregar = 1;
      this.modalAgregar = true;
      setTimeout(() => {
          this.$nextTick(() => this.$refs.ingresacantidadagregar.focus());
      }, 110);

    },

    marcarCorrecto: function(row) {
      this.articuloEditable = row;
      this.cantidad_editable = row.cantidad_salida; //La cantidad que se recibe en este caso de edicion directa sería la cantidad de salida
      this.edicion_directa = true;
      this.editar();

    },

    editar: function() {

      if (this.edicion_directa == true || this.$refs.form.validate()) {
        if (this.articuloEditable.cantidad_recibida.toString() != this.cantidad_editable.toString()) {
          if (this.tipo_orden == "Traspaso") {

            window.dialogLoader.show('Espere un momento por favor..');
            //Si el estatus del modelo esta en "Pediente" cambiarlo a "En Proceso de Entrada"
            if (this.model.estatus != "En Proceso de Entrada")
              this.model.estatus = "En Proceso de Entrada";

            this.model.articulos.forEach(e => {
              if (e.id_articulo == this.articuloEditable.id_articulo) {
                e.cantidad_recibida = parseFloat(this.cantidad_editable);
                this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada
              }
            });

            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
              .then(response => {
                this.model._rev = response.data.rev;
                this.modalEditar = false;
                this.modalAgregar = false;
              })
              .catch(error => {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
                this.codigo_articulo = "";
                this.edicion_directa = false;
                this.$nextTick(() => this.$refs.ingresaarticulo.focus());
              });

            //COMPRA
          } else if (this.tipo_orden == "Compra") {
            window.dialogLoader.show('Espere un momento por favor..');

            if (this.model.estatus != "En Proceso de Entrada")
              this.model.estatus = "En Proceso de Entrada";

            this.model.articulos.forEach(e => {
              if (e.id_articulo == this.articuloEditable.id_articulo) {
                e.cantidad_recibida = parseFloat(this.cantidad_editable);
                this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada
              }
            });

            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
              .then(response => {
                this.model._rev = response.data.rev;
                this.modalEditar = false;
                this.modalAgregar = false;
              })
              .catch(error => {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
                this.codigo_articulo = "";
                this.edicion_directa = false;
                this.$nextTick(() => this.$refs.ingresaarticulo.focus());
              });
          } else if (this.tipo_orden == "Venta") {
            window.dialogLoader.show('Espere un momento por favor..');

            this.model.articulos_devolver.forEach(e => {
              if (e.id_articulo == this.articuloEditable.id_articulo) {
                e.cantidad_recibida = parseFloat(this.cantidad_editable);
                this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada
              }
            });

            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
              .then(response => {
                this.model._rev = response.data.rev;
                this.modalEditar = false;
                this.modalAgregar = false;
              })
              .catch(error => {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
                this.codigo_articulo = "";
                this.edicion_directa = false;
                this.$nextTick(() => this.$refs.ingresaarticulo.focus());
              });
          }
        } else {
          this.modalEditar = false;
          this.modalAgregar = false;
          this.codigo_articulo = "";
          this.$nextTick(() => this.$refs.ingresaarticulo.focus());
        }
      }
    },

    agregar: function(){      
      if(this.edicion_directa == true || this.$refs.form.validate()) {
          this.cantidad_editable = parseFloat(this.articuloEditable.cantidad_recibida) + parseFloat(this.cantidad_agregar);
          this.editar();        
      }
    },

    aceptar_todo: function(){
      
        if (this.tipo_orden == "Traspaso") {

          window.dialogLoader.show('Espere un momento por favor..');
          //Si el estatus del modelo esta en "Pediente" cambiarlo a "En Proceso de Entrada"
          if (this.model.estatus != "En Proceso de Entrada")
            this.model.estatus = "En Proceso de Entrada";

          this.model.articulos.forEach(e => {            
              e.cantidad_recibida = parseFloat(e.cantidad_salida);
              this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada            
          });

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(response => {
              this.model._rev = response.data.rev;              
            })
            .catch(error => {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();              
            });

          //COMPRA
        } else if (this.tipo_orden == "Compra") {
          window.dialogLoader.show('Espere un momento por favor..');

          if (this.model.estatus != "En Proceso de Entrada")
            this.model.estatus = "En Proceso de Entrada";

          this.model.articulos.forEach(e => {            
              e.cantidad_recibida = parseFloat(e.cantidad_salida);              
              this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada            
          });

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(response => {
              this.model._rev = response.data.rev;              
            })
            .catch(error => {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();                            
            });
        } else if (this.tipo_orden == "Venta") {
          window.dialogLoader.show('Espere un momento por favor..');

          this.model.articulos_devolver.forEach(e => {            
              e.cantidad_recibida = parseFloat(e.cantidad_salida);              
              this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada            
          });

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(response => {
              this.model._rev = response.data.rev;              
            })
            .catch(error => {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();                            
            });
        }        
    },

    buscarArticulo: function() {
      if (this.codigo_articulo != null && this.codigo_articulo != '') {
        //TRASPASO
        if (this.tipo_orden == "Traspaso" && this.codigo_articulo != null) { //Es TRASPASO

          window.dialogLoader.show('Espere un momento por favor..');

          //Buscar codigo en los articulos del modelo
          //Si lo encuentra agregar 1 a cantidad_recibida y guardar
          //Si no lo encuentra mostrar alerta

          var buscar_codigo = this.codigo_articulo.toString().trim();
          var encontrado = 0;
          this.model.articulos.forEach(e => {
            if (e.codigo_articulo.includes(buscar_codigo) && e.estatus != "Recibido") {
              encontrado = 1;
              this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada
              if (this.model.estatus != "En Proceso de Entrada")
                this.model.estatus = "En Proceso de Entrada";

              if (e.cantidad_recibida != null && e.cantidad_recibida != "")
                e.cantidad_recibida += parseFloat(1);
              else
                e.cantidad_recibida = parseFloat(1);

              window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                  this.model._rev = response.data.rev;
                  this.recargarArticulos();
                })
                .catch(error => {
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                    footer: ""
                  });
                }).then(() => {
                  window.dialogLoader.hide();
                  this.codigo_articulo = "";
                  this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                });
            }

          });

          if (encontrado == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡No encontrado!",
              text: "Artículo no encontrado. Favor de verificar.",
              footer: ""
            }).then(() => {
              this.codigo_articulo = "";
              this.$nextTick(() => this.$refs.ingresaarticulo.focus());
            });
          }



          //COMPRA
        } else if (this.tipo_orden == "Compra" && this.codigo_articulo != null) { //Es COMPRA

          window.dialogLoader.show('Espere un momento por favor..');
          //Buscar codigo en los articulos del modelo
          //Si lo encuentra agregar 1 a cantidad_recibida y guardar
          //Si no lo encuentra mostrar alerta

          var buscar_codigo = this.codigo_articulo.toString().trim();
          var encontrado = 0;
          this.model.articulos.forEach(e => {
            if (e.codigo_articulo.includes(buscar_codigo) && e.estatus != "Recibido") {
              encontrado = 1;
              this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada
              if (this.model.estatus != "En Proceso de Entrada")
                this.model.estatus = "En Proceso de Entrada";

              if (e.cantidad_recibida != null && e.cantidad_recibida != "")
                e.cantidad_recibida += parseFloat(1);
              else
                e.cantidad_recibida = parseFloat(1);

              window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                  this.model._rev = response.data.rev;
                  this.recargarArticulos();
                })
                .catch(error => {
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                    footer: ""
                  });
                }).then(() => {
                  window.dialogLoader.hide();
                  this.codigo_articulo = "";
                  this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                });
            }

          });

          if (encontrado == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡No encontrado!",
              text: "Artículo recibido o no encontrado. Favor de verificar.",
              footer: ""
            }).then(() => {
              this.codigo_articulo = "";
              this.$nextTick(() => this.$refs.ingresaarticulo.focus());
            });
          }
        } else if (this.tipo_orden == "Venta" && this.codigo_articulo != null) { //Es VENTA

          window.dialogLoader.show('Espere un momento por favor..');
          //Buscar codigo en los articulos del modelo
          //Si lo encuentra agregar 1 a cantidad_recibida y guardar
          //Si no lo encuentra mostrar alerta

          var buscar_codigo = this.codigo_articulo.toString().trim();
          var encontrado = 0;
          this.model.articulos_devolver.forEach(e => {
            if (e.codigo_articulo.includes(buscar_codigo) && e.estatus_entrada != "Recibido") {
              encontrado = 1;
              this.articulosModificados.push(e.id_articulo); //Para detectar qué articulos se les dió entrada

              if (e.cantidad_recibida != null && e.cantidad_recibida != "")
                e.cantidad_recibida += parseFloat(1);
              else
                e.cantidad_recibida = parseFloat(1);

              window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                  this.model._rev = response.data.rev;
                  this.recargarArticulos();
                })
                .catch(error => {
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro. Intente nuevamente.",
                    footer: ""
                  });
                }).then(() => {
                  window.dialogLoader.hide();
                  this.codigo_articulo = "";
                  this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                });
            }

          });

          if (encontrado == 0) {
            window.dialogLoader.hide();
            this.$swal({
              type: "info",
              title: "¡No encontrado!",
              text: "Artículo recibido o no encontrado. Favor de verificar.",
              footer: ""
            }).then(() => {
              this.codigo_articulo = "";
              this.$nextTick(() => this.$refs.ingresaarticulo.focus());
            });
          }
        } else {
          this.$nextTick(() => this.$refs.ingresaarticulo.focus());
        }
      } else { //buscar código por nombre
        this.modalBuscar = true;
        this.buscar_codigo_articulo = "";
        var self = this;
        setTimeout(function() {
          self.$nextTick(() => self.$refs.selectarticulo.focus());
        }, 300);

      }
    },

    cerrarModal: function() {
      this.modalBuscar = false;
      var self = this;
      setTimeout(function() {
        self.$nextTick(() => self.$refs.ingresaarticulo.focus());
      }, 300);
    },

    recargarArticulos: function() {
      if (this.tipo_orden == "Compra") {
        for (var artkey in this.model.articulos) {
          this.model.articulos[artkey].cantidad_salida = this.model.articulos[artkey].cantidad;
        }
      } else if (this.tipo_orden == "Venta") {
        for (var artkey in this.model.articulos_devolver) {
          this.model.articulos_devolver[artkey].cantidad_salida = this.model.articulos_devolver[artkey].cantidad_devolver;
        }
      } else
        this.registros.items = this.model.articulos;
    },

    buscarCodigo: function() {
      var sucursalActivaNombre = this.$local_storage.get("sb_nombre_sucursal", "");
      var sucursalActiva = this.sucursal_activa;
      this.articulosModificados = [];
      this.observacion = "";

      //TRASPASO
      if (this.codigo != null && this.codigo.toString().trim().startsWith('T')) { //Es TRASPASO
        this.tipo_orden = "Traspaso";

        window.dialogLoader.show('Espere un momento por favor..');
        let where = {
          "type": "lotes_traspasos",
          "id_sucursal_destino": this.sucursal_activa,
          "clave_traspaso": this.codigo.toString().trim(),
        };
        axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
            withCredentials: true,
            data: {
              selector: where,
              use_index:"_design/d888f06a1b3f9ff26f69af5e578e55cb7e14082d"
            },
          }).then(response => {
            if (response.data.docs.length) {

              var r = response.data.docs[0];
              if (r.id_sucursal_destino == sucursalActiva) {
                if (["Salida de Origen", "En Proceso de Entrada"].includes(r.estatus)) {
                  this.model = r;
                  var articulos = r.articulos;
                  this.registros.items = articulos;
                  setTimeout(() => {
                    this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                  }, 300);
                } else {
                  this.registros.items = [];
                  var mensaje = (["Pendiente", "En Proceso de Salida"].includes(r.estatus)) ? "No se ha dado salida a la orden. Favor de verificar." : "Ya se dió entrada a la orden. Favor de verificar.";
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: mensaje,
                    footer: ""
                  }).then(() => {
                    this.codigo = "";
                    this.$nextTick(() => this.$refs.ingresacodigo.focus());
                  });
                }
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "La orden de entrada pertenece a la sucursal: " + r.nombre_sucursal_destino + ". Favor de verificar.",
                  footer: ""
                }).then(() => {
                  this.codigo = "";
                  this.$nextTick(() => this.$refs.ingresacodigo.focus());
                });
              }

            } else {
              this.registros.items = [];
              this.$swal({
                type: "info",
                title: "¡No Encontrado!",
                text: "No se encontraron registros para el código ingresado. Favor de verificar.",
                footer: ""
              }).then(() => {
                this.codigo = "";
                this.$nextTick(() => this.$refs.ingresacodigo.focus());
              });

            }
          })
          .catch(error => {
            console.log(error)
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al buscar el código. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });

        //COMPRA
      } else if (this.codigo != null && this.codigo.toString().trim().startsWith('C')) { //Es COMPRA
        this.tipo_orden = "Compra";

        window.dialogLoader.show('Espere un momento por favor..');
        let where = {
          "type": "compras",
          "no_orden": this.codigo.toString().trim(),
        };
        axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
            withCredentials: true,
            data: {
              selector: where,
              use_index:"_design/5b9056a9c6374f6cd2be9719dd5879df89779dd7"
            },
          }).then(response => {
            if (response.data.docs.length) {
              var r = response.data.docs[0];

              if (r.id_sucursal_destino == sucursalActiva) {
                  if (!["Pedido Recibido", "Orden Finalizada", "Orden Cancelada"].includes(r.estatus)) {

                    this.model = r;
                    var articulos = r.articulos;

                    if (articulos.length > 0) {
                      for (var artkey in articulos) {
                        articulos[artkey].cantidad_salida = articulos[artkey].cantidad;
                      }
                      this.registros.items = articulos;
                      setTimeout(() => {
                        this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                      }, 300);
                    } else {
                      this.$swal({
                        type: "warning",
                        title: "¡Orden sin Artículos!",
                        text: "No se encontraron artículos en la orden para esta sucursal.",
                        footer: ""
                      }).then(() => {
                        this.registros.items = [];
                        this.codigo_articulo = "";
                        this.codigo = "";
                        this.model._id = "";
                        this.$nextTick(() => this.$refs.ingresacodigo.focus());
                      });

                    }

                } else {
                  this.registros.items = [];
                  var mensaje = r.estatus == "Orden Cancelada" ? "La orden está cancelada. Favor de verificar." : "Ya se dió entrada a la orden. Favor de verificar.";
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: mensaje,
                    footer: ""
                  }).then(() => {
                    this.codigo = "";
                    this.$nextTick(() => this.$refs.ingresacodigo.focus());
                  });
                }
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "La orden de entrada pertenece a la sucursal: " + r.nombre_sucursal_destino + ". Favor de verificar.",
                  footer: ""
                }).then(() => {
                  this.codigo = "";
                  this.$nextTick(() => this.$refs.ingresacodigo.focus());
                });
              }




            } else {
              this.registros.items = [];
              this.$swal({
                type: "info",
                title: "¡No Encontrado!",
                text: "No se encontraron registros para el código ingresado. Favor de verificar.",
                footer: ""
              }).then(() => {
                this.codigo = "";
                this.$nextTick(() => this.$refs.ingresacodigo.focus());
              });

            }
          })
          .catch(error => {
            console.log(error)
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al buscar el código. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });

      //VENTA
      } else if (this.codigo != null && this.codigo.toString().trim().startsWith('V')) {
        this.tipo_orden = "Venta";

          window.dialogLoader.show('Espere un momento por favor..');
          let where = {
            "type": "ventas",
            "sucursal._id": this.sucursal_activa,
            "ticket": this.codigo.toString().trim(),
          };
          //console.log(where);

          axios({
            method: 'POST',
            url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
            withCredentials: true,
            data: {
              selector: where,
              use_index:"_design/a7d2e051b59b11b94b48688c00647000ac8326f9"
            },
          }).then(response => {
              if (response.data.docs.length) {
                  //Se aceptan las canceladas, ya que de una cancelación puede haber productos devueltos
                  if(["Finalizada", "Facturada","Cancelada", "Factura Global"].includes(response.data.docs[0].estatus)){
                      var model = response.data.docs[0];

                      if(model.fecha_entrada != ""){
                        this.registros.items = [];
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ya se dió entrada a la orden ingresada. Favor de verificar.",
                            footer: ""
                        }).then(()=>{
                            this.codigo = "";
                            this.$nextTick(() => this.$refs.ingresacodigo.focus());
                        });
                      } else {
                        this.model = model;

                        if (model["articulos_devolver"].length > 0) {
                          for (var artkey in model["articulos_devolver"]) {
                              model["articulos_devolver"][artkey].cantidad_salida = model["articulos_devolver"][artkey].cantidad_devolver;
                            }
                          this.registros.items = model["articulos_devolver"];
                          setTimeout(() => {
                              this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                          }, 300);

                        } else {
                          this.$swal({
                            type: "warning",
                            title: "¡Orden sin Artículos!",
                            text: "No se encontraron artículos en la orden para esta sucursal.",
                            footer: ""
                          }).then(() => {
                            this.registros.items = [];
                            this.codigo_articulo = "";
                            this.codigo = "";
                            this.model._id = "";
                            this.$nextTick(() => this.$refs.ingresacodigo.focus());
                          });

                        }

                      }

                  } else {
                      this.registros.items = [];
                      this.$swal({
                          type: "error",
                          title: "¡Operación no Permitida!",
                          text: "La orden no se ha pagado. Favor de verificar.",
                          footer: ""
                      }).then(()=>{
                          this.codigo = "";
                          this.$nextTick(() => this.$refs.ingresacodigo.focus());
                      });
                  }
              } else {
                  this.registros.items = [];
                  this.$swal({
                      type: "info",
                      title: "¡No Encontrado!",
                      text: "No se encontraron registros para el código ingresado. Favor de verificar.",
                      footer: ""
                  }).then(()=>{
                      this.codigo = "";
                      this.$nextTick(() => this.$refs.ingresacodigo.focus());
                  });

              }
          })
          .catch(error => {
            console.log(error)
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al buscar el código. Favor de comunicarse con soporte.",
                footer: ""
              });
          }).then(()=>{
            window.dialogLoader.hide();
          });

      } else {
        this.$swal({
          type: "info",
          title: "¡Código no reconocido!",
          text: "Ingrese nuevamente el código.",
          footer: ""
        }).then(() => {
          this.codigo = "";
          this.$nextTick(() => this.$refs.ingresacodigo.focus());
        });
      }

    },
    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != "") {
        if (parseFloat(value) < 0)
          return "No debe ser negativo";
        else if(parseFloat(value) > 100000)
          return "Cantidad demasiado grande";
      }
      return true;

    },

    buscarOtro: function() {
      this.registros.items = [];
      this.codigo_articulo = "";
      this.codigo = "";
      this.model._id = "";
      this.$nextTick(() => this.$refs.ingresacodigo.focus());
    },

    getDiferencias: function() {
      var diferencias = 0;
      var suma_recibido = 0;
      var articulosOrden = this.registros.items.map(a => {
        return a.id_articulo;
      });
      if (this.tipo_orden == "Venta") {
          this.model.articulos_devolver.forEach(a => {
            suma_recibido += a.cantidad_recibida;
            if (articulosOrden.includes(a.id_articulo) && a.cantidad_salida != a.cantidad_recibida)
                diferencias += 1;
        });
      } else {
          this.model.articulos.forEach(a => {
            suma_recibido += a.cantidad_recibida;
            if (articulosOrden.includes(a.id_articulo) && a.cantidad_salida != a.cantidad_recibida)
                diferencias += 1;
        });
      }

      return [diferencias, suma_recibido];
    },

    finalizarEntrada: function() {
      //Revisar que todas las cantidades coincidan, sino mandar confirmación si desea continuar
      var diferencias = this.getDiferencias();
        if(diferencias[1] == 0){
          this.$swal({
                type: "error",
                title: "¡Operación No Permitida!",
                text: "No ha señalado la entrada de ningún artículo. Favor de Verificar.",
                footer: "",                
            });
        }
        else if (diferencias[0] > 0) {
        this.$swal({
          type: "info",
          title: "Cuidado",
          text: "Hay diferencias en las cantidades. ¿Aún asi desea finalizar la entrada?",
          footer: "",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Si",
          cancelButtonText: "No"
        }).then(resultado => {
          if (resultado.value) {
            this.observacion = "";
            this.modalObservacion = true;
          }
        });
      } else {
        this.finalizar();
      }
    },

    finalizarObservacion: function() {
      if (this.$refs.formobservacion.validate()) {
        this.finalizar();
        this.modalObservacion = false;
      }
    },

    finalizar: async function() {
      try {
        if (this.tipo_orden == "Traspaso" || this.tipo_orden == "Compra") {
          //(13-Feb-2025): Solicitaron que para Traspasos y Compras se pida credenciales de supervisor antes de Finalizar
          this.no_identificacion = "";
          this.codigo_supervisor = "";
          this.modal_supervisor = true;
          
        } else if (this.tipo_orden == "Venta") {
          window.dialogLoader.show('Espere un momento por favor..');

          var errores = [];
          var bulkDestino = [];          
          var self = this;

          this.model.articulos_devolver.forEach(art => {

            if (art.cantidad_recibida > 0 && art.estatus_entrada == "Pendiente" && this.articulosModificados.includes(art.id_articulo)) { //Pendiente, Recibido
              var precio_compra = 0;
              for(var key in art.lotes){
                precio_compra = art.lotes[key].precio_compra;
              }
              var dataDestino = {
                id_sucursal: self.model.sucursal._id, //Requerido
                id_articulo: art.id_articulo, //Requerido
                cantidad: parseFloat(art.cantidad_recibida), //Requerido positivo
                precio_compra: parseFloat(precio_compra), //Requerido
                fecha_caducidad: "", //Opcional
                fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"), //Requerido
                origen: "venta cancelada",
                usuario: this.$local_storage.get("sb_usuario"),
              };

              var copyData = JSON.parse(JSON.stringify(dataDestino));
              bulkDestino.push(copyData);
            }

          });

          //Afectar el inventario destino
          for (var k in bulkDestino) {
            await window.funciones_lotes.nuevaEntradaInventario(bulkDestino[k]).then(result => {

              this.model.articulos_devolver.forEach(art => {
                if (art.id_articulo == bulkDestino[k].id_articulo) {
                  art.estatus_entrada = "Recibido";

                  if (art.lotes_destino == null || (art.lotes_destino != null && art.lotes_destino.length == 0))
                    art["lotes_destino"] = result;
                  else if (art.lotes_destino != null && art.lotes_destino.length > 0)
                    art.lotes_destino = art.lotes_destino.concat(result);
                }
              });

            }).catch(err => {
              errores.push(err);
            });
          }

          if (errores.length == 0) {
            //Guardar model y poner estatus en "Recibido"
            this.model.estatus_entrada = "Recibido";
            this.model.usuario_entrada = this.$local_storage.get("sb_usuario");
            this.model.fecha_entrada = window.moment().format("YYYY-MM-DDTHH:mm:ss");
            if (this.observacion != null && this.observacion != "")
              this.model.observaciones_entrada += " " + this.$local_storage.get("sb_usuario") + ": " + this.observacion;

            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
              .then(response => {
                this.model._rev = response.data.rev;
                window.dialogLoader.showSnackbar('El proceso finalizó correctamente... ', {
                  color: 'success'
                });
                this.registros.items = [];
                this.codigo_articulo = "";
                this.codigo = "";
                this.model._id = "";
                this.$nextTick(() => this.$refs.ingresacodigo.focus());
              })
              .catch(error => {
                console.log("Error1: ", error);
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al finalizar el proceso. Por favor intente nuevamente.",
                  footer: ""
                });
              }).then(() => {
                window.dialogLoader.hide();
              });
          } else {

            //Guardamos cambios en el modelo para guardar los estatus de los articulos que si se hayan afectado correctamente
            window.axios
              .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
              .then(response => {
                this.model._rev = response.data.rev;
              })
              .catch(error => {
                console.log("Error2", error);
              }).then(() => {
                console.log("Error3: ");
                window.dialogLoader.hide();
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Ocurrió un error al finalizar el proceso. Intente nuevamente.",
                  footer: ""
                });
              });

          }
        }
      } catch (error) {
        console.log("Error4: ", error);
        window.dialogLoader.hide();
        this.$swal({
          type: "error",
          title: "¡Operación no Permitida!",
          text: "Ocurrió un error al finalizar el proceso. Intente nuevamente.",
          footer: ""
        });
      }

    },

    verificaSupervisor: function(){
      if(this.$refs.formsupervisor.validate()){
        let data = {'selector': {
                  'type': 'supervisores', 
                  "no_identificacion": this.no_identificacion,
                  "codigo": this.codigo_supervisor,                    
              },
              "use_index":"_design/35591118acc2252f7ff2dd68a1a53aedf02e373f",
              "fields": ["id_usuario", "usuario"]
          }

        window.axios
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
          .then(response => {
            if (response.data.docs.length > 0) {
                this.modal_supervisor = false;
                this.finalizarTraspasoCompra();
            } else{
              this.$swal({
                icon: "error",
                title: "¡Supervisor No Válido!",
                text: "El supervisor no es válido. Intente nuevamente por favor.",
                footer: ""
              });
            }            
          })
          .catch(error => {
            this.$swal({
              icon: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al verificar supervisor. Intente nuevamente por favor.",
              footer: ""
            });
          });
      }      

    },

    finalizarTraspasoCompra: async function(){
      //TRASPASO
      if (this.tipo_orden == "Traspaso") {
        window.dialogLoader.show('Espere un momento por favor..');

        var errores = [];
        var bulkDestino = [];

        var self = this;

        this.model.articulos.forEach(art => {

          var dataDestino = {
            id_sucursal: self.model.id_sucursal_destino, //Requerido
            id_articulo: "", //Requerido
            cantidad: "", //Requerido positivo
            precio_compra: "", //Requerido
            fecha_caducidad: "", //Opcional
            fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"), //Requerido
            origen: "traspaso",
            usuario: this.$local_storage.get("sb_usuario"),
          };

          if (art.estatus == "Salida" && parseFloat(art.cantidad_recibida) > 0) {
            var cantidad_recibida_aux = parseFloat(art.cantidad_recibida);

            for (var k in art.lotes) {
              var lote = art.lotes[k];

              dataDestino.id_articulo = art.id_articulo;
              dataDestino.precio_compra = lote.precio_compra;
              dataDestino.fecha_caducidad = lote.fecha_caducidad;
              var copyData = "";

              cantidad_recibida_aux += lote.cantidad; //lote cantidad es negativa, se hace resta

              if (cantidad_recibida_aux == 0) {
                //Significa que el lote ya cubrió la cantidad real recibida aux, se guarda y ya no se continua con los demas lotes
                dataDestino.cantidad = parseFloat(Math.abs(lote.cantidad)); //Requerido positivo
                copyData = JSON.parse(JSON.stringify(dataDestino)); //para no generar referencia
                bulkDestino.push(copyData);
                break;
              } else if (cantidad_recibida_aux > 0) {
                //Significa que el lote no cubre la cantidad real recibida aux y hay que continuar con los demás lotes
                dataDestino.cantidad = parseFloat(Math.abs(lote.cantidad)); //Requerido positivo
                copyData = JSON.parse(JSON.stringify(dataDestino));
                bulkDestino.push(copyData);
              } else if (cantidad_recibida_aux < 0) {
                //Significa que el lote sobrepasa la cantidad real recibida aux, hay que guardar solo la cantidad que faltaba cubrir y ya no continuar con los demas lotes
                var cantidadFaltante = parseFloat(Math.abs(lote.cantidad)) - parseFloat(Math.abs(cantidad_recibida_aux));
                dataDestino.cantidad = cantidadFaltante; //Requerido positivo
                copyData = JSON.parse(JSON.stringify(dataDestino));
                bulkDestino.push(copyData);
                break;
              }

            } //for lotes

            if (cantidad_recibida_aux > 0) {
              //Si los lotes no cubrieron la cantidad real, quiere decir que se recibió mas de lo que se envió
              //se guarda de todos modos la cantidad sobrante con los datos del ultimo lote
              dataDestino.cantidad = parseFloat(cantidad_recibida_aux); //Requerido positivo
              var copyData = JSON.parse(JSON.stringify(dataDestino));
              bulkDestino.push(copyData);
            }

          }

        });


        //Afectar el inventario destino
        for (var k in bulkDestino) {
          await window.funciones_lotes.nuevaEntradaInventario(bulkDestino[k]).then(result => {

            this.model.articulos.forEach(art => {
              if (art.id_articulo == bulkDestino[k].id_articulo) {
                art.estatus = "Recibido";
                if (art.lotes_destino == null || (art.lotes_destino != null && art.lotes_destino.length == 0))
                  art["lotes_destino"] = result;
                else if (art.lotes_destino != null && art.lotes_destino.length > 0)
                  art.lotes_destino = art.lotes_destino.concat(result);
              }
            });

          }).catch(err => {
            errores.push(err);
          });
        }

        if (errores.length == 0) {
          //Guardar model y poner estatus en "Recibido"
          this.model.estatus = "Recibido";
          this.model.usuario_entrada = this.$local_storage.get("sb_usuario");
          this.model.fecha_finalizado = window.moment().format("YYYY-MM-DDTHH:mm:ss");
          this.model.observacion_entrada = this.observacion;
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(response => {
              this.model._rev = response.data.rev;
              window.dialogLoader.showSnackbar('El proceso finalizó correctamente... ', {
                color: 'success'
              });
              this.registros.items = [];
              this.codigo_articulo = "";
              this.codigo = "";
              this.model._id = "";
              this.$nextTick(() => this.$refs.ingresacodigo.focus());
            })
            .catch(error => {
              console.log("Error1: ", error);
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al finalizar el proceso. Por favor intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });
        } else {

          //Guardamos cambios en el modelo para guardar los estatus de los articulos que si se hayan afectado correctamente
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(response => {
              this.model._rev = response.data.rev;
            })
            .catch(error => {
              console.log("Error2", error);
            }).then(() => {
              console.log("Error3: ");
              window.dialogLoader.hide();
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al finalizar el proceso. Intente nuevamente.",
                footer: ""
              });
            });

        }
        //COMPRA
      } else if (this.tipo_orden == "Compra") {
        window.dialogLoader.show('Espere un momento por favor..');

        var errores = [];
        var bulkDestino = [];          
        var self = this;

        this.model.articulos.forEach(art => {

          //if (art.cantidad_recibida > 0 && art.estatus == "Pendiente" && this.articulosModificados.includes(art.id_articulo)) { //Pendiente, Recibido
          if (art.cantidad_recibida > 0 && art.estatus == "Pendiente") { //Pendiente, Recibido  //Se deja asi, porque a veces desde Compras, ya trae la cantidad recibida, entonces no se mete a articulosModificados
            var dataDestino = {
              id_sucursal: self.model.id_sucursal_destino, //Requerido
              id_articulo: art.id_articulo, //Requerido
              id_compra: self.model._id,
              cantidad: parseFloat(art.cantidad_recibida), //Requerido positivo
              precio_compra: parseFloat(art.precio_proveedor), //Requerido
              fecha_caducidad: "", //Opcional
              fecha_entrada: window.moment().format("YYYY-MM-DDTHH:mm:ss"), //Requerido
              origen: "compra",
              usuario: this.$local_storage.get("sb_usuario"),
            };

            var copyData = JSON.parse(JSON.stringify(dataDestino));
            bulkDestino.push(copyData);
          }

        });
        
        //Afectar el inventario destino
        for (var k in bulkDestino) {
          await window.funciones_lotes.nuevaEntradaInventario(bulkDestino[k]).then(result => {

            this.model.articulos.forEach(art => {
              if (art.id_articulo == bulkDestino[k].id_articulo) {                
                art.estatus = "Recibido";
                art.fecha_recibio = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                art.sucursal_recibio = self.model.nombre_sucursal_destino;
                art.usuario_recibio = this.$local_storage.get("sb_usuario");

                if (art.lotes_destino == null || (art.lotes_destino != null && art.lotes_destino.length == 0))
                  art["lotes_destino"] = result;
                else if (art.lotes_destino != null && art.lotes_destino.length > 0)
                  art.lotes_destino = art.lotes_destino.concat(result);                
              }
            });

          }).catch(err => {
            errores.push(err);
          });
        }

        if (errores.length == 0) {
          //Guardar model y poner estatus en "Orden Finalizada" ya que ahora se maneja como factura, si ya se recibió la factura se finaliza
          this.model.estatus = "Orden Finalizada";
          this.model.fecha_recibio_mercancia = window.moment().format("YYYY-MM-DDTHH:mm:ss");
          this.model.fecha_finalizada = this.model.fecha_recibio_mercancia;
          this.model.usuario_entrada = this.$local_storage.get("sb_usuario");
          if (this.observacion != null && this.observacion != "")
            this.model.observaciones_entrada += " " + this.$local_storage.get("sb_usuario") + ": " + this.observacion;

          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(async response => {
              this.model._rev = response.data.rev;
              await window.funciones_lotes.actualizar_precio_compra_articulos(this.model._id);//Actualizar precios de compra en catalogo de articulos
              window.dialogLoader.showSnackbar('El proceso finalizó correctamente... ', {
                color: 'success'
              });
              this.registros.items = [];
              this.codigo_articulo = "";
              this.codigo = "";
              this.model._id = "";
              this.$nextTick(() => this.$refs.ingresacodigo.focus());

              //Actualizamos el pedido origen con estatus de proceso de entrada
              window.axios
              .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model.id_orden_origen)
              .then(responseOrigen => {
                  var model_pedido = responseOrigen.data;
                  model_pedido.estatus = "En Proceso de Entrada";
                  window.axios
                  .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + model_pedido._id + '?conflicts=true', model_pedido)
                  .then(responsea => {
                    //Orden actualziada
                  }).catch(errora => {
                    console.log("Error al actualizar orden origen: ", errora);
                  });
                
              }).catch(error => {
                console.log("Error al obtener orden origen: ", error);
              });

            }).catch(error => {
              console.log("Error1: ", error);
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al finalizar el proceso. Por favor intente nuevamente.",
                footer: ""
              });
            }).then(() => {
              window.dialogLoader.hide();
            });
        } else {

          //Guardamos cambios en el modelo para guardar los estatus de los articulos que si se hayan afectado correctamente
          window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
            .then(response => {
              this.model._rev = response.data.rev;
            })
            .catch(error => {
              console.log("Error2", error);
            }).then(() => {
              console.log("Error3: ");
              window.dialogLoader.hide();
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al finalizar el proceso. Intente nuevamente.",
                footer: ""
              });
            });

        }
      }
    },


  }
};
</script>
<style scoped>
.v-card__text >>> .bg-mal {
  background-color: #fcb7af !important;
}

.v-card__text >>> .bg-bien {
  background-color: #d8f8e1 !important;
}
</style>
